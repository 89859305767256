/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Existing styles ... */

/* Flip Card Styles */
.perspective {
  perspective: 1000px;
}



.shadow-custom {
  --tw-shadow-color: #d0ff01; /* This sets the color of the shadow */
  --tw-shadow: 0 10px 15px -3px var(--tw-shadow-color), 
               0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-shadow);
}

.button:hover {
  --tw-shadow-color: #a6ff00; /* Change this color for hover state */
}

.flip-card {
  /* Adjust the height as needed */
  position: relative;
  height: 250px; /* Example height, adjust as necessary */
  border-radius: 8px;
  overflow: hidden; 
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}


.flip-card-front{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 1rem; /* Adjust padding as needed */


  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  background-color: #fff;
  /* Add a box shadow for depth */
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  
}


